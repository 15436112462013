<script setup lang="ts">
  import { onClickOutside, useTimeoutFn } from '@vueuse/core'
  import { ref, watchEffect, nextTick } from 'vue'
  import { provideToast } from 'vue-toastification'
  import IconClose from '@/assets/icons/Close.svg'
  import toastConfig from '@/entrypoints/toastConfig'
  import BaseContainer from './BaseContainer.vue'
  import { WidthMode } from './baseModal'
  import type { ClassProp } from '../types/props'

  export interface Props {
    open: boolean
    contentClass?: ClassProp
    containerClass?: ClassProp
    closeOnClickOutside?: boolean
    widthMode?: WidthMode
  }

  export interface Slots {
    default: (props: { close: () => void }) => unknown
  }

  const props = withDefaults(defineProps<Props>(), {
    contentClass: '',
    containerClass: '',
    widthMode: WidthMode.Fit,
  })

  defineSlots<Slots>()

  const emit = defineEmits<{
    close: []
  }>()

  const dialog = ref<HTMLDialogElement | null>(null)
  const { start: startToClose, stop: abortClosing } = useTimeoutFn(
    () => dialog.value?.close(),
    300,
    {
      immediate: false,
    }
  )

  watchEffect(async () => {
    if (dialog.value) {
      abortClosing()
      if (props.open) {
        // Delay opening the dialog to allow for the slot to be rendered
        await nextTick()
        dialog.value.showModal()
      } else {
        // Delay closing the dialog to allow the animation to finish
        startToClose()
      }
    }
  })

  // Make sure toasts show in top-layer https://developer.mozilla.org/en-US/docs/Glossary/Top_layer
  provideToast({
    ...toastConfig,
    container: async () => {
      return new Promise((resolve) => {
        watchEffect(() => {
          if (dialog.value) {
            resolve(dialog.value)
          }
        })
      })
    },
  })

  const container = ref<HTMLDivElement | null>(null)
  onClickOutside(container, () => {
    if (props.closeOnClickOutside) {
      emit('close')
    }
  })
</script>

<template>
  <dialog
    ref="dialog"
    class="gutter-stable-both backdrop:bg-black-var/90 light:supports-[backdrop-filter]:backdrop:bg-white/70 max-h-screen w-full max-w-none overflow-auto bg-transparent backdrop:backdrop-blur-md backdrop:transition-opacity supports-[backdrop-filter]:backdrop:bg-black/70"
    :class="[
      {
        'ease-in backdrop:opacity-20 backdrop:delay-100 backdrop:duration-200':
          !open,
        'ease-out backdrop:opacity-100 backdrop:duration-300': open,
      },
    ]"
    data-testid="base-modal-root"
    :inert="!open"
    @cancel.prevent="$emit('close')"
    @close="$emit('close')"
  >
    <transition
      enter-active-class="ease-out duration-300"
      enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="ease-in duration-200"
      leave-from-class="opacity-100 translate-y-0 sm:scale-100"
      leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <BaseContainer
        v-if="open"
        class="p-20"
        data-testid="base-modal-outer-container"
        :full-width="widthMode === WidthMode.Screen"
      >
        <div
          ref="container"
          class="text-white-var bg-gray-var-900 mx-auto max-w-[calc(100vw-11rem)] overflow-auto rounded-2xl pb-14 shadow-xl"
          :class="[
            containerClass,
            widthMode === WidthMode.Fit ? 'w-fit' : 'w-full',
          ]"
          data-testid="base-modal-container"
        >
          <div class="sticky left-0 flex justify-end">
            <BaseButton
              class="m-1.5 -mb-2 p-4"
              data-testid="base-modal-button-close"
              @click="$emit('close')"
            >
              <span class="sr-only">
                {{ $t('global.navigation.close') }}
              </span>
              <IconClose class="h-6 w-6" />
            </BaseButton>
          </div>
          <div
            class="mx-8"
            :class="contentClass"
            data-testid="base-modal-content"
          >
            <slot :close="() => $emit('close')" />
          </div>
        </div>
      </BaseContainer>
    </transition>
  </dialog>
</template>
