export enum WidthMode {
  /**
   * Width is determined by the content, akin to `width: fit-content`
   */
  Fit = 'fit',
  /**
   * Width is always the full allowed width up to a reasonable maximum
   */
  Full = 'full',
  /**
   * Width is always the full screen width minus outer padding
   */
  Screen = 'screen',
}
